import React from 'react'
import styled from 'styled-components'
let songurl = "12xd9vbV1Z2ZHkezUQnusF"
const FooterDiv = styled.div`
iframe{
    border-radius:12px;
    width="100%"; 
    height="180px";
    
}
h3{
    font-size: 1.5rem;
    font-weight: 100;
    color:rgb(53, 91, 109);
    margin-top:0;
}
    `
export default function index() {
  return (
    <div>

        
        <FooterDiv>
        <h3>song of the week</h3>    
        <iframe title='myFrame' src={'https://open.spotify.com/embed/track/' + songurl + '?utm_source=generator'} frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        
        </FooterDiv>
    </div>
  )
}
