import React from 'react';
import './App.css';
//import Logo from "./components/logo/index.js"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home.js";
import Blog from "./pages/blog.js";
import Arsal from "./pages/arsal.js";
import B28042022 from "./blog/b28042022.js";
import B20022023 from "./blog/b20022023.js";
import B12052023 from "./blog/b12052023.js";

function App() {
///*<Logo/>*/
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/arsal" element={<Arsal/>} />
        <Route path="/B28-04-2022" element={<B28042022/>} />
        <Route path="/B20-02-2023" element={<B20022023/>} />
        <Route path="/B12-05-2023" element={<B12052023/>} />
        

      </Routes>
    </Router>
    
    
    
  );
}

export default App;
