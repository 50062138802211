import Navbar from "../components/navbar/index.js";
import Intro from "../components/introduction/index.js"
import Skills from "../components/skills/index.js";
import Footer from "../components/footer/index.js";
export default function App() {
    ///*<Logo/>*/
      return (
        <div className='mainclass'>
          <Navbar/>
          <Intro/>
          <Skills/>
          <Footer/>
        </div>
        
      );
    }
    
