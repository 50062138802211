import Navbar from '../components/navbar/index.js';
import styled from 'styled-components';

const Content = styled.div`
width:60%;
@media only screen and (max-width: 450px){
  width:90%;
}

` 
export default function B28042022() {

  return (
    <div>
        <Navbar/>
        <div className='mainclass'>
          <Content>
            <h1>Personal website with react</h1>
            <p>
              This is my personal website with react.
              I have learned basics of react and styled-components.<br/>
              I used to have website with html and css. Then i realized that i can use react.
              Since big and strong websites require solid frameworks, i decided to learn react.<br/>
              First days of learning react wasn't easy. Because i didn't have any experience with it.
              But later, when i practiced, i started to understand how to use react. And then i asked
              myself, why don't i make my website with react? And new website would be much easier to maintain, since 
              react is being made by components. 

            </p>
          </Content>
            

        
        </div>
        
    </div>
  )
}
