import Navbar from '../components/navbar/index.js';
import styled from 'styled-components';

const Content = styled.div`
width:60%;
@media only screen and (max-width: 450px){
  width:90%;
}
a{
  text-decoration: none;
  color: #355b6d;
  transition: 0.3s;
}
a:hover{
    color:#42c1ba;
    transform: scale(1.4);
}
}
` 
export default function B12052023() {

  return (
    <div>
        <Navbar/>
        <div className='mainclass'>
          <Content>
          <h2>deploying minecraft server with google cloud for free</h2>
            <p>
              actually story begins with me wanting to play minecraft with my friends for free.<br/>
              you might say Ömer there is something called aternos. yeah i've heard about it and used a lot.
              but aternos only provides 2gbs of ram and it won't stay open for all the time. which for a free service <br/>
              it's pretty neat i tell you that. it also provides mod, version, plugin management. but i figured that i needed more
              ram to play with many people.<br/>
              so i started to look for other free hosting services. i found AWS was providing 750 hours of t2.micro instance
              for free. First i thought ok this is it 750/24 = 31,25 1 month of server that awesome.<br/>
              but when i take deep step into it i've learned that t2.micro has 1 GiB RAM and 1 vCPU. then i
              started to look for other instances and found t2.medium which has 4 GiB RAM and 2 vCPUs later i've come 
              to decision that i will start my server on t2.medium while thinking it will provide me 750 hours.<br/>
              anyway i've made some search i installed java on aws linux server which was hell of a progress.
              uploaded my mc server files to the server through WinSCP and run it. Server was working well, 40-50ms
              ping was pretty enough for my location. Everything was going perfect until i learned i was being charged.
              I should have read the terms lol. Anyway for almost 29 hours of usage cost me $1.83 with tax included. I immediately
              closed the server. If only 1 US dollar wasn't equal to 19,5 Turkish liras. Anyways i still don't when 
              i need to make the payment but we'll see.<br/>
              </p>
              <h3>Choosing Google Cloud</h3>
              <p>as i said i was looking for free hosting services and i found that
                google cloud provides 3 months of hosting services worth of $300.
                 i couldn't miss that oppurtunity. since this is google, it wouldn't let me 
                 down right? <br/>
                 anyhow i created google cloud account and it begun. i've looked few tutorials like   <a style={{ textDecoration: 'none' }} href='https://medium.com/@manbobo2012/host-a-minecraft-server-on-google-cloud-with-automatic-deployment-and-backup-f00d49a1a306'>this medium blog</a>
              </p>
              <p>
                first create your google cloud account, and start a new project
              </p>
              <p>go to compute engine and enable it</p>
              <p>then create an instance. now select a region that is closest to you
                in order to get lower ping. my choice was europe-west3. 
              </p>
              <p>
                after that, select your machine type. since google giwing away free credits help yourselves.
                i chosed  e2 medium because it was enough for me.
              </p>
              <p>you can pass display device, confidential vm service and container</p>
              <p>at the boot disk option hit the change. i prefer ubuntu OS because its 
                popular and for me it was easier to install java. change your size as you desire.
                but even 10gb will be enough for a simple server.
              </p>
              <p>in firewall section allow both traffics</p>
              <p>open advanced options and networking. add a network tag like minecraft-server.
                afterwards we will use this in firewall rules. <b>hit the create button</b>
              </p>
              <p>now in search button type firewall. you will see the firewall VPC network. click on it.
                 create a new firewall rule. pick a name that you find desirable. in target tags type minecraft-server
              </p>
              <p>scroll down and type the same <b>Network tags</b> that you previously chosed in creation
                 of the instance in Target tags section </p>
              <p>Put 0.0.0.0/0 in source IPv4 ranges</p> 
              <p>for protocols and ports, select specified protocols and ports.
                put 25565 in tcp section. because minecraft uses tcp protocol in 25565 port.              </p>
              <p>create the rule</p>
              <p>now your server is created and ready to go, you just need to adjust java and server files</p>
              
              <h3>setting up java</h3>

              <p>download the server file from <a style={{ textDecoration: 'none' }} href='https://www.minecraft.net/en-us/download/server' target='_blank' rel="noreferrer">minecraft.net</a>
              and upload it to your server</p>

              <p>run the server file.</p>

              <p>now you will have eula file in your server folder. edit it with <br/><b>nano eula.txt</b> <br/>linux command
              set eula to the true so you will be accepting mojangs eula terms</p>
              
              <p>run the server file again and now you have your server, enjoy!</p>
              <p>for extra settings you can edit server.properties file</p>
          </Content>

        
        </div>
        
    </div>
  )
}
