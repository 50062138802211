import Navbar from '../components/navbar/index.js';
import Intro from '../components/introduction/index.js';
import styled from 'styled-components';
const LinkDiv = styled.div`
    width:70%;
    display: flex;
    flex-direction: column;
    
    a{
      text-decoration: none;
      color: #355b6d; 
      font-weight: 100;
      
    }
    h3,h4{
      display:inline;
      float:left;
      margin:4px;
    }
    h3{
      padding-top: 0.25rem;
    }
    h4{
      
      padding-top: 0.25rem;
    }


    @media (max-width: 769px) {
      width:100%;
      h3,h4{
        font-size:0.8rem;
      }
      p{
        font-size:0.8rem;
      }
    }
`
export default function index() {
  return (
    
    <div className='mainclass'>
          <Navbar/>
          <Intro/>
          <LinkDiv>
          <a href='/B28-04-2022'><h3>28.04.2022</h3><h3>Personal website with react</h3></a>
          <a href='/B20-02-2023'><h3>20.02.2023</h3><h3>Whats going on & where was i?</h3></a>
          <a href='/B12-05-2023'><h3>12.05.2023</h3><h3>hosting minecraft server on google cloud</h3></a>
          

          </LinkDiv>
    </div>
  )
}
