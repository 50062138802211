import Navbar from '../components/navbar/index.js';
import styled from 'styled-components';

const Content = styled.div`
width:60%;
@media only screen and (max-width: 450px){
  width:90%;
}

` 
export default function B20022023() {

  return (
    <div>
        <Navbar/>
        <div className='mainclass'>
          <Content>
            <h1>whats going on & where was i?</h1>
            <p>
              Wow its been almost one year since i publiched my personal website with react
              <br/>
              let me give a little brief about what i have been doing well its a bit complicated <br/>
              in may and june of 2022 i had midterms and finals and im glad that went well. in summer i had to work
              in our restaurant cafe in my hometown for whole summer holiday. in that time i didn't have any time to look at react or anything else<br/>
              anyway, after summer holiday i started second year of my university and i tell you that it was not easy. Even though students from other software
              related majors telling us that our data structures and algorithms was not that hard i found it pretty hard. maybe because i have 
              never studied algorithms. anyway, this first semester was way better than the last years'.<br/>
              and now its 20 february, how time flies... now our university is online due to earthquake and its consequences. its ok but
              i have to work. actually i kinda got a job in our university. but since the semester is not started yet, the job is not certain but
              if i get the job it will be a huge step in my cv<br/>
              i'm hoping that it will help me to get a job in summer which is awesome<br/>
              job is about back and probably includes asp.netcore mvc and crud operations. <br/>
              while writing this blog, thoughts of mine, i realised that i always tend to use same words. i need to solve that
              problem and honestly dont know how. we'll see. till next time, see you. 
            </p>
          </Content>

        
        </div>
        
    </div>
  )
}
