import React from "react";
import styled from "styled-components";
const Nav = styled.div`
margin: 0 auto;
padding: 2rem 1rem;
  font-size:150%;
  display: flex;
  justify-content: space-evenly;
  a{
    text-decoration: none;
    color: #355b6d;
    transition: 0.3s;
  }
  a:hover{
      color:#42c1ba;
      transform: scale(1.4);
  }
`
export default function App(){
    
    return(
        <Nav>
            <a href='/'>homepage</a>
            <a href='/blog'>blog</a>           
        </Nav>

    );
}